import revive_payload_client_VK40EI90t1 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GYVvNCM6xP from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vt3lBbDYqB from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_XJkcKpNk89 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uqEwT9HTG1 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lrYeTIjgxw from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bxAR0t87yv from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_NjftaKmj9i from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_YnmHkdunPb from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.2_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_cwlby3fvqhxq36lnouke7zx2k4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_aWJ9YgGFCp from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.26.3_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_VK40EI90t1,
  unhead_GYVvNCM6xP,
  router_vt3lBbDYqB,
  payload_client_XJkcKpNk89,
  navigation_repaint_client_uqEwT9HTG1,
  check_outdated_build_client_lrYeTIjgxw,
  chunk_reload_client_bxAR0t87yv,
  plugin_vue3_NjftaKmj9i,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YnmHkdunPb,
  primevue_plugin_egKpok8Auk,
  plugin_client_aWJ9YgGFCp,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]